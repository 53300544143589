<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="document"
              v-model="filter.document"
              name="document"
              :label="$t('CPF ou CNPJ')"
              type="text-mask"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              :placeholder="$t('CPF ou CNPJ')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="name"
              v-model="filter.name"
              name="name"
              :label="$t('Nome')"
              :placeholder="$t('Nome')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="person-type"
              v-model="filter.personType"
              name="personType"
              type="vue-select"
              :placeholder="$t('Todas')"
              :options="personTypes()"
              :label="$t('Tipo Pessoa')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="status"
              v-model="filter.status"
              name="status"
              :label="$t('Status')"
              :placeholder="$t('Todos')"
              type="vue-select"
              :options="activeInactiveTypes()"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="email"
              v-model="filter.email"
              name="email"
              type="text"
              :label="$t('E-mail')"
              :placeholder="$t('nome@email.com')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="region"
              v-model="filter.region"
              type="vue-select"
              :label="$t('Região')"
              :options="storeRegions()"
              :placeholder="$t('Todas')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="province"
              v-model="filter.province"
              type="vue-select"
              :label="$t('Estado')"
              :options="provinces()"
              :placeholder="$t('Todos')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="city"
              v-model="filter.city"
              name="city"
              :label="$t('Cidade')"
              :placeholder="$t('Nome da cidade')"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="supplierTable"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('SUPPLIER.NAME'))"
        :fields="fields"
        :items="suppliers"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(personType)="row">
          {{ getPersonType[row.item.personType] }}
        </template>

        <template #cell(telephones)="row">
          <div
            v-for="(number, index) in row.item.telephones.map((t) => t.number)"
            :key="`${row.index}_${index}`"
          >
            {{ number | phone }}
            <br>
          </div>
        </template>

        <template #cell(email)="row">
          {{ row.item.email }}
          <br>
          {{ row.item.emailInvoice }}
        </template>

        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-activate="!row.item.isStore && $can('Activate', 'Supplier')"
            :show-deactivate="!row.item.isStore && $can('Deactivate', 'Supplier')"
            :show-update="!row.item.isStore && $can('Update', 'Supplier')"
            :show-delete="!row.item.isStore && $can('Delete', 'Supplier')"
            :show-read-only="$can('Read', 'Supplier')"
            @update="showUpdateSupplierPage(row)"
            @delete="deleteSupplier(row)"
            @activate="activateDeactivateSupplier(row)"
            @deactivate="activateDeactivateSupplier(row)"
            @read-only="showReadOnlySupplierPage(row)"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="supplier-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="$can('Create', 'Supplier')"
      :main-tooltip="$t('Adicionar Fornecedor')"
      @click="showCreateSupplierPage"
    />
  </div>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EStatusBadge } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { personTypes, province, statusTypes, storeDomains } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'

export default {
  name: 'ProviderList',
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EStatusBadge,
    EFilters,
    EGridActions,
  },

  mixins: [personTypes, statusTypes, province, storeDomains],

  data() {
    return {
      fetching: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/purchase/supplier', {
      suppliers: 'suppliers',
      paging: 'paging',
      sorting: 'sorting',
      filter: 'filter',
    }),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          thStyle: { width: '100px' },
          tdClass: 'text-right',
          sortable: true,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Pessoa'),
          key: 'personType',
          tdClass: 'text-left',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Telefone'),
          key: 'telephones',
          tdClass: 'text-left',
          thStyle: { width: '150px' },
        },
        {
          label: this.$t('E-mail / E-mail NF'),
          key: 'email',
          tdClass: 'text-left',
          tdAttr: { style: 'word-break: break-word;' },
          thStyle: { width: '200px' },
          sortable: true,
        },
        {
          label: this.$t('Cidade'),
          key: 'city',
          tdClass: 'text-left',
          thStyle: { width: '250px' },
          formatter: (value, index, item) =>
            item.addresses.map(ad => `${ad.city}-${ad.province}`).join(' / '),
        },
        {
          label: this.$t('Status'),
          key: 'active',
          thStyle: { width: '90px' },
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/purchase/supplier/supplierMaintain', {
      stCleanSupplierMaintain: 'cleanState',
    }),
    ...mapActions('pages/purchase/supplier', [
      'fetchSuppliers',
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'resetFilter',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchSuppliers()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilter()
      this.getData()
    },
    filterData() {
      this.setFilter(this.filter)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCreateSupplierPage() {
      this.stCleanSupplierMaintain()
      this.$router.push({ name: 'supplier-add' })
    },

    async showUpdateSupplierPage(row) {
      const { id } = row.item
      this.$router.push({ name: 'supplier-maintain', params: { id } })
    },
    async showReadOnlySupplierPage(row) {
      const { id } = row.item
      this.$router.push({ name: 'supplier-read-only', params: { id } })
    },

    async deleteSupplier(client) {
      const { id } = client.item
      try {
        const confirm = await this.confirm()
        if (confirm) {
          await this.$http.delete(`/api/suppliers/${id}`)

          this.getData()
          this.showSuccess({ message: this.$t('Fornecedor removido com sucesso') })
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    async activateDeactivateSupplier(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          const path = row.item.active ? 'deactivate' : 'activate'
          await this.$http({
            url: `/api/suppliers/${path}/${row.item.id}`,
            method: 'PUT',
          })
          this.getData()
          this.showSuccess({
            message: this.$t(`${row.item.active === true ? 'Inativado' : 'Ativado'} com sucesso`),
          })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style></style>
