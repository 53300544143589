var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "document",
                          name: "document",
                          label: _vm.$t("CPF ou CNPJ"),
                          type: "text-mask",
                          mask: ["###.###.###-##", "##.###.###/####-##"],
                          placeholder: _vm.$t("CPF ou CNPJ"),
                        },
                        model: {
                          value: _vm.filter.document,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "document", $$v)
                          },
                          expression: "filter.document",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "name",
                          name: "name",
                          label: _vm.$t("Nome"),
                          placeholder: _vm.$t("Nome"),
                        },
                        model: {
                          value: _vm.filter.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "name", $$v)
                          },
                          expression: "filter.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "person-type",
                          name: "personType",
                          type: "vue-select",
                          placeholder: _vm.$t("Todas"),
                          options: _vm.personTypes(),
                          label: _vm.$t("Tipo Pessoa"),
                        },
                        model: {
                          value: _vm.filter.personType,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "personType", $$v)
                          },
                          expression: "filter.personType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "status",
                          name: "status",
                          label: _vm.$t("Status"),
                          placeholder: _vm.$t("Todos"),
                          type: "vue-select",
                          options: _vm.activeInactiveTypes(),
                        },
                        model: {
                          value: _vm.filter.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "status", $$v)
                          },
                          expression: "filter.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "email",
                          name: "email",
                          type: "text",
                          label: _vm.$t("E-mail"),
                          placeholder: _vm.$t("nome@email.com"),
                        },
                        model: {
                          value: _vm.filter.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "email", $$v)
                          },
                          expression: "filter.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "region",
                          type: "vue-select",
                          label: _vm.$t("Região"),
                          options: _vm.storeRegions(),
                          placeholder: _vm.$t("Todas"),
                        },
                        model: {
                          value: _vm.filter.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "region", $$v)
                          },
                          expression: "filter.region",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "province",
                          type: "vue-select",
                          label: _vm.$t("Estado"),
                          options: _vm.provinces(),
                          placeholder: _vm.$t("Todos"),
                        },
                        model: {
                          value: _vm.filter.province,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "province", $$v)
                          },
                          expression: "filter.province",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "city",
                          name: "city",
                          label: _vm.$t("Cidade"),
                          placeholder: _vm.$t("Nome da cidade"),
                        },
                        model: {
                          value: _vm.filter.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "city", $$v)
                          },
                          expression: "filter.city",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "supplierTable",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(_vm.$tc("SUPPLIER.NAME")),
              fields: _vm.fields,
              items: _vm.suppliers,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(personType)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.getPersonType[row.item.personType]) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(telephones)",
                fn: function (row) {
                  return _vm._l(
                    row.item.telephones.map(function (t) {
                      return t.number
                    }),
                    function (number, index) {
                      return _c("div", { key: row.index + "_" + index }, [
                        _vm._v(" " + _vm._s(_vm._f("phone")(number)) + " "),
                        _c("br"),
                      ])
                    }
                  )
                },
              },
              {
                key: "cell(email)",
                fn: function (row) {
                  return [
                    _vm._v(" " + _vm._s(row.item.email) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(row.item.emailInvoice) + " "),
                  ]
                },
              },
              {
                key: "cell(active)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.active },
                    }),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "is-active": row.item.active,
                        "show-activate":
                          !row.item.isStore && _vm.$can("Activate", "Supplier"),
                        "show-deactivate":
                          !row.item.isStore &&
                          _vm.$can("Deactivate", "Supplier"),
                        "show-update":
                          !row.item.isStore && _vm.$can("Update", "Supplier"),
                        "show-delete":
                          !row.item.isStore && _vm.$can("Delete", "Supplier"),
                        "show-read-only": _vm.$can("Read", "Supplier"),
                      },
                      on: {
                        update: function ($event) {
                          return _vm.showUpdateSupplierPage(row)
                        },
                        delete: function ($event) {
                          return _vm.deleteSupplier(row)
                        },
                        activate: function ($event) {
                          return _vm.activateDeactivateSupplier(row)
                        },
                        deactivate: function ($event) {
                          return _vm.activateDeactivateSupplier(row)
                        },
                        "read-only": function ($event) {
                          return _vm.showReadOnlySupplierPage(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "supplier-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$can("Create", "Supplier")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Fornecedor") },
            on: { click: _vm.showCreateSupplierPage },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }